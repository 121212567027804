@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  :root {
    --bg-color: #ffffff;
    --primary: #3c3836;
    --on-primary: #ffffff;
    --primary-text: #3c3836;
    --secondary: #c97f5f;
    --on-secondary: #ffffff;
    --bg-footer: #d1d5db;
    --bg-surface: #d1d5db;
    --error: #7d1b1b;
  }

  .theme-light {
    --bg-color: #ffffff;
    --primary: #3c3836;
    --on-primary: #ffffff;
    --primary-text: #3c3836;
    /* --secondary: #c97f5f; */
    --secondary: #dba45d;
    --on-secondary: #ffffff;
    --bg-footer: #d1d5db;
    --bg-surface: #d1d5db;
    --border-on-surface: #d1d5db;
    --error: #7d1b1b;
  }

  .theme-dark {
    --bg-color: #0e0e0e;
    --primary: #e5d8d1;
    --on-primary: #0e0e0e;
    --primary-text: #d6cac4;
    /* --secondary: #c97f5f; */
    --secondary: #dba45d;
    --on-secondary: #ffffff;
    --bg-footer: #010101;
    --bg-surface: #010101;
    --border-on-surface: #010101;
    --error: #7d1b1b;
  }

  .social-media-container {
    @apply w-9 text-skin-secondary cursor-pointer bg-skin-on-secondary hover:text-skin-on-secondary hover:bg-skin-secondary rounded-full p-2;
  }

  .product-list-header {
    @apply typo-display6;
  }

  @screen md {
    .product-list-header {
      @apply typo-display4;
    }
  }
}

@font-face {
  /* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
  font-family: 'family-light';
  src: local('family-light'), url('../assets/fonts/karma/Karma-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'family-regular';
  src: local('family-regular'), url('../assets/fonts/karma/Karma-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'family-medium';
  src: local('family-medium'), url('../assets/fonts/karma/Karma-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'family-semibold';
  src: local('family-semibold'), url('../assets/fonts/karma/Karma-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'family-bold';
  src: local('family-bold'), url('../assets/fonts/karma/Karma-Bold.ttf') format('truetype');
}

body {
  margin: 0px;
  padding: 0px;
}

::-webkit-scrollbar {
  width: 0px;
}

.typo-display2 {
  color: var(--primary-text);
  font-size: 56px;
  letter-spacing: -0.5px;
  line-height: 68px;
  font-family: 'family-bold';
}

.typo-display3 {
  color: var(--primary-text);
  font-size: 44px;
  letter-spacing: -0.5px;
  line-height: 54px;
  font-family: 'family-bold';
}

.typo-display4 {
  color: var(--primary-text);
  font-size: 32px;
  letter-spacing: 0px;
  line-height: 40px;
  font-family: 'family-bold';
}

.typo-display5 {
  color: var(--primary-text);
  font-size: 24px;
  letter-spacing: 0px;
  line-height: 32px;
  font-family: 'family-semibold';
}

.typo-display6 {
  color: var(--primary-text);
  font-size: 20px;
  letter-spacing: 0.15px;
  line-height: 26px;
  font-family: 'family-semibold';
}

.typo-display6-regular {
  color: var(--primary-text);
  font-size: 20px;
  letter-spacing: 0.15px;
  line-height: 26px;
  font-family: 'family-regular';
}

.typo-title-lg {
  color: var(--primary-text);
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 23px;
  font-family: 'family-medium';
}

.typo-title {
  color: var(--primary-text);
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 22px;
  font-family: 'family-medium';
}

.typo-body-lg {
  color: var(--primary-text);
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 24px;
  font-family: 'family-regular';
}

.typo-body {
  color: var(--primary-text);
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 22px;
  font-family: 'family-regular';
}

.btn-primary {
  display: inline-block;
  justify-content: center;
  font-size: 18px;
  padding: 8px 24px;
  letter-spacing: 0.4px;
  border-radius: 10px;
  background: var(--secondary);
  color: var(--on-secondary);
  border: 0;
  font-family: 'family-medium';
  transition: background 0.4s;
}

.btn-primary:hover {
  background: var(--primary);
}

.typo-error {
  color: var(--error);
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 22px;
  font-family: 'family-regular';
}

/* Slider */
.slider-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.slider-container .slider {
  display: flex;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-in-out;
}

.slider-container .slider .slide {
  width: 0px;
  height: 100%;
  flex-shrink: 0;
  background-size: cover;
}

.slider-container .slider .active {
  opacity: 1;
}

.slider-container .slider .prev,
.slider-container .slider .next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  cursor: pointer;
  border: none;
  background: none;
  color: white;
}

.slider-container .slider .prev {
  left: 10px;
}

.slider-container .slider .next {
  right: 10px;
}

/* Animation Effects */
.animation-fade-in {
  animation-name: fadeIn;
  animation-duration: 1.2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.animated-toggle-switch {
  left: 0;
  transition: left 0.2s ease-in;
}

.animated-toggle-switch.toggle {
  left: 50%;
}

.sidebar {
  transition: width 0.3s ease;
}

.loader {
  width: 27px;
  height: 27px;
  border: 2px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
